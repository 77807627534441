<template>
  <div class="menu-wrap" @click="stopTheEvent">
    <h3>Anzeigen-Typ:</h3>
    <div>
      <el-checkbox v-model="showTurbo">Turbo-Anzeigen</el-checkbox>
    </div>
    <div>
      <el-checkbox v-model="showStandard">Normale-Anzeigen</el-checkbox>
    </div>

    <h3>Reperatur-Typ:</h3>
    <div>
      <el-checkbox v-model="showSmartRepair">Smart-Repair</el-checkbox>
    </div>
    <div>
      <el-checkbox v-model="showProfessionalRepair">Professional</el-checkbox>
    </div>
    <div>
      <el-checkbox v-model="showLeasingRepair">Leasing</el-checkbox>
    </div>
    <div class="button-bar">
      <el-button @click="submit">Filter Anwenden</el-button>
    </div>
  </div>
</template>

<script>
import constants from "@/constants";
export default {
  name: "DamageTableFilterContent",
  data() {
    return {
      showTurbo: true,
      showStandard: true,
      showSmartRepair: true,
      showProfessionalRepair: true,
      showLeasingRepair: true
    };
  },
  created() {
    if (this.$store.state.damageList.filter) {
      const {
        requestTypes,
        repairTypes
      } = this.$store.state.damageList.filter;
      this.showTurbo = requestTypes.includes(constants.requestType.turbo);
      this.showStandard = requestTypes.includes(constants.requestType.standard);
      this.showSmartRepair = repairTypes.includes(constants.repairType.smart);
      this.showProfessionalRepair = repairTypes.includes(
        constants.repairType.professional
      );
      this.showLeasingRepair = repairTypes.includes(
        constants.repairType.leasing
      );
    }
  },
  methods: {
    stopTheEvent: event => event.stopPropagation(),
    submit() {
      this.$emit("submit", {
        showTurbo: this.showTurbo,
        showStandard: this.showStandard,
        showSmartRepair: this.showSmartRepair,
        showProfessionalRepair: this.showProfessionalRepair,
        showLeasingRepair: this.showLeasingRepair
      });
    }
  }
};
</script>

<style scoped lang="scss">
.menu-wrap {
  padding: 10px 20px;
}
.button-bar {
  text-align: center;
  padding-top: 40px;
}
h3 {
  font-size: 17px;
  font-weight: normal;
  margin: 30px 0 10px 0;
  color: rgb(175, 175, 175);
}
</style>
