<template>
  <div class="page">
    <DamageTable :view="$refs.view" />
  </div>
</template>

<script>
import DamageTable from '@/components/DamageTable'

export default {
  name: 'Damages',
  components: {
    DamageTable
  }
}
</script>

<style lang="scss" scoped>
  .search {
    margin: 20px;
  }
</style>
