<template>
  <div class="damage-table">
    <div
      :class="{ 'damage-table-toolbar': 1, transparent: transparentToolbar }"
    >
      <slot name="toolbar"></slot>
    </div>
    <table>
      <thead>
        <tr>
          <slot name="head"></slot>
        </tr>
      </thead>
      <tbody>
        <slot name="body"></slot>
      </tbody>
    </table>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
export default {
  name: "Table",
  props: ["loading", "transparentToolbar"],
  data() {
    return {
      loadingInstance: null
    }
  },
  watch: {
    loading() {
      if (this.loading && !this.loadingInstance) {
        this.loadingInstance = Loading.service({ target: '.damage-table' });
      } else {
        this.$nextTick(() => {
          if (this.loadingInstance) {
            this.loadingInstance.close();
            this.loadingInstance = null;
          }
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.damage-table {
  background: white;
  border-radius: 6px;
  // border: 1px solid rgb(238, 238, 238);

  .damage-table-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    min-height: 12px;

    &.transparent {
      background: #f4f6f8;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background: #f4f6f8;

      tr td {
        padding: 10px 0;
        font-weight: bold;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }
}
</style>
