<template>
  <div class="car-image-name">
    <HoverImagePreview :src="thumbImages[0]" />
    <div class="car-name">
      {{ `${damage.carManufacturer} ${damage.carModel}`
      }}<span class="caryear">({{ damage.carYear }})</span>
    </div>
  </div>
</template>

<script>
import HoverImagePreview from "@/components/HoverImagePreview";
export default {
  name: "CarImageName",
  props: ["damage"],
  computed: {
    thumbImages() {
      const images = (this.damage && this.damage.images) || [];
      return images.map(img => img.thumbKey || img.key);
    }
  },
  components: {
    HoverImagePreview
  }
};
</script>

<style scoped lang="scss">
.car-image-name {
  display: flex;
  align-content: center;

  .car-name {
    padding-left: 10px;
    line-height: 32px;
  }
}
</style>
