<template>
  <td>
    <div :class="{ clickable: sortable, td: 1 }" @click="changeSort">
      {{ title }}
      <fa-icon v-if="sortable && value && value.field === field" :icon="value.order === 1? 'sort-alpha-down' : 'sort-alpha-up'" />
    </div>
  </td>
</template>

<script>
export default {
  name: "TableHeadCell",
  props: ["title", "sortable", "value", "field"],
  methods: {
    changeSort() {
      const order =
        this.value && this.value.field === this.field && this.value.order === -1
          ? 1
          : -1;
      this.$emit("input", {
        field: this.field,
        order
      });
    }
  }
};
</script>

<style scoped lang="scss">
.td {
  display: inline-block;
  border-radius: 4px;
  height: 100%;
  padding: 0 7px;
  margin-left: -7px;

  &.clickable {
    cursor: pointer;

    &:hover {
      background: #f8f9fa;
    }
  }
}
</style>
