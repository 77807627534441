<template>
  <TableRow
    @click="open"
    :class="{ 'not-available': !isAvailable, available: isAvailable }"
  >
    <td class="td-name">
      <span class="counter">{{ index + 1 }}</span>
      <CarImageName :damage="item" />
    </td>
    <td>
      <div class="list">
        <div
          v-for="damageType in damageTypes"
          :key="damageType.type"
          :title="damageType.title"
        >
          {{ damageType.text }}
        </div>
      </div>
    </td>
    <td>{{ customer }}</td>
    <td>{{ totalOffers }} ({{ this.item.offers.OFFER_TYPE_COUNTER || 0 }})</td>
    <td>{{ createdAt }}</td>
    <td class="td-price">
      <span>
        <div class="turbo" v-if="isTurbo">
          Turbo
        </div>
        <div class="price" v-else>
          {{ price }}
        </div>
        <el-popover
          trigger="click"
          v-model="menuVisible">
          <div class="damage-actions">
            <el-button @click="deleteDamage" type="danger">Entfernen</el-button>
          </div>
          <div slot="reference" class="user">
            <el-button plain circle icon="el-icon-more"></el-button>
          </div>
        </el-popover>
      </span>
    </td>
  </TableRow>
</template>

<script>
import TypeService from "@/services/type";
import constants from "@/constants";
import moment from "moment";
import TableRow from "@/components/Table/TableRow";
import CarImageName from "@/components/CarImageName";
import apiService from "@/services/api";

export default {
  name: "DamageTableItem",
  props: ["item", "index"],
  created() {
    this.updateCreatedAtInterval = setInterval(this.updateCreatedAt, 5000);
  },
  mounted() {
    this.updateCreatedAt();
  },
  beforeDestroy() {
    clearInterval(this.updateCreatedAtInterval);
  },
  data() {
    return {
      updateCreatedAtInterval: null,
      createdAt: null
    };
  },
  computed: {
    damageTypes() {
      return (
        this.item &&
        this.item.damages.map(damage => ({
          type: damage.damageType,
          text: this.damageToText(damage),
          title:
            (damage.length && `${damage.length}cm`) ||
            (damage.size && `${damage.size}cm`) ||
            null
        }))
      );
    },
    distance() {
      return (Math.round(this.item._distanceFromGarage, 2) / 1000).toFixed(2);
    },
    isTurbo() {
      return this.item.requestType === constants.requestType.turbo;
    },
    price() {
      if (this.isTurbo) {
        return "TURBO";
      }
      return this.item.price + ",00 €";
    },
    isAvailable() {
      return !this.item.__frontend__deleted;
    },
    totalOffers() {
      return (
        this.item.offers.OFFER_TYPE_OFFER + this.item.offers.OFFER_TYPE_COUNTER + this.item.offers.OFFER_TYPE_TURBO
      ) || 0;
    },
    customer() {
      console.log(this.item);
      return `${this.item.author.firstname} ${this.item.author.lastname}`;
    }
  },
  methods: {
    typeToText: TypeService.toText,
    deleteDamage() {
       this.$emit('delete')
    },
    damageToText(damage) {
      if (damage.damageType === constants.damageType.polish) {
        return TypeService.toText(damage.type);
      }
      return TypeService.toText(damage.damageType);
    },
    async open() {
      if (this.isAvailable) {
        const [token, appUrl] = await Promise.all([
          apiService.getUserToken(this.item.userId),
          apiService.getAppUrl(),
        ]);
        window.open(`${appUrl}/damage/${this.item._id}?token=${token}`, '_blank').focus();
      }
    },
    updateCreatedAt() {
      this.createdAt = this.item && moment(this.item.createdAt).fromNow();
    }
  },
  components: {
    TableRow,
    CarImageName
  }
};
</script>

<style scoped lang="scss">
td {
  position: relative;
}

tr.not-available {
  cursor: default;
  opacity: 0.2;
  &:hover {
    background: inherit;
  }
}

.td-price {
  white-space: nowrap;
  width: auto;

  span {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-left: 15px;
    }
  }
}

.counter {
  position: absolute;
  font-size: 12px;
  color: black;
  left: -24px;
  top: calc(50% - 6px);
  color: rgb(177, 177, 177);
}

.distance {
  color: gray;
}

.caryear {
  margin-left: 3px;
  color: gray;
}

.available {
  .turbo {
    color: var(--md-theme-default-primary-on-background, #448aff);
    display: flex;
    justify-content: left;
    align-items: center;
    // animation: blink-animation 1s ease infinite alternate;

    i {
      margin: 0 10px 0 0;
      color: var(--md-theme-default-primary-on-background, #448aff) !important;
    }
  }
}

@keyframes blink-animation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.15;
  }
}
</style>
