import constants from "../constants";
const type = {
  toText(type) {
    switch (type) {
      case constants.offerType.turbo:
        return "Turboangebot";

      case constants.offerType.offer:
        return "Angebot";

      case constants.offerType.counter:
        return "Gegenangebot";

      case constants.damageType.dent:
        return "Delle";

      case constants.damageType.scratch:
        return "Kratzer";

      case constants.damageType.bump:
        return "Beule";

      case constants.damageType.rim:
        return "Felge";

      case constants.damageType.polish:
        return "Polieren";

      case constants.repairType.professional:
        return "Professionell";

      case constants.repairType.smart:
        return "Smart-Repair";

      case constants.repairType.leasing:
        return "Leasing";

      case constants.polishType.polish:
        return "Polieren";

      case constants.polishType.wax:
        return "Polieren (+ Wachsen)";

      case constants.polishType.nano:
        return "Nanoversiegelung";

      default:
        return null;
    }
  }
};

export default type;
