<template>
  <tr @click="$emit('click')">
    <slot />
  </tr>
</template>

<script>
export default {
  name: "TableRow",
  props: []
};
</script>

<style scoped lang="scss">
tr {
  cursor: pointer;

  &:hover {
    background: #fbfcfd;
  }

  td {
    padding: 14px 0;
    border-bottom: 1px solid rgb(238, 238, 238);

    .list {
      display: flex;

      > div {
        &:first-child::before {
          content: "";
        }
        &::before {
          content: ",";
        }
      }
    }

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }
}
</style>
