<template>
  <div class="image-wrap" @mouseenter="showPopover" @mouseleave="hidePopover">
    <div
      v-if="blobUrl"
      class="image"
      :style="{ 'background-image': `url(${blobUrl})` }"
    ></div>
    <div v-else-if="failed" class="image-loading">
      E!
    </div>
    <div v-else class="image-loading">
      ...
    </div>
    <div class="overlay"></div>

    <div
      v-if="blobUrl"
      class="popover md-elevation-4"
      :style="{ 'background-image': `url(${blobUrl})`, ...popoverStyles }"
    ></div>
  </div>
</template>

<script>
import filesService from "@/services/files";
export default {
  name: "HoverImagePreview",
  props: ["src"],
  data() {
    return {
      blobUrl: null,
      popoverVisible: false,
      popoverTarget: null,
      failed: false
    };
  },
  mounted() {
    this.updateBlob();
  },
  watch: {
    src() {
      this.updateBlob();
    }
  },
  computed: {
    popoverStyles() {
      if (!this.popoverTarget) return;

      const box = this.popoverTarget.getBoundingClientRect();
      return {
        display: this.popoverVisible ? "block" : "none",
        left: box.left + 45 + "px",
        top: box.top - 300 / 2 + box.height / 2 + "px"
      };
    }
  },
  methods: {
    async updateBlob() {
      if (!this.src) {
        this.blobUrl = null;
        return;
      }
      try {
        this.blobUrl = URL.createObjectURL(await filesService.getImage(this.src));
        this.failed = false;
      } catch (error) {
        this.failed = true;
      }
    },

    showPopover(e) {
      this.popoverTarget = e.target;
      this.popoverVisible = true;
    },

    hidePopover() {
      this.popoverVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.image-wrap {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 10px;
}
.image {
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.image-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  color: gray;
  font-size: 11px;

  i {
    margin: 0 10px 0 0;
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0);
  border-radius: inherit;
  opacity: 0;
  transition: opacity ease-in 0.1s;
  cursor: pointer;

  &:hover {
    opacity: 0.4;
  }
}

.popover {
  background-size: cover;
  position: fixed;
  width: 400px;
  height: 300px;
  border-radius: 20px;
  display: none;
  z-index: 1;
}
</style>
