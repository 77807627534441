<template>
  <Table :loading="isFetching">
    <template v-slot:toolbar>
      <div></div>
      <div class="filter-wrap">
        <FilterMenu @apply="applyFilter" />
      </div>
    </template>

    <template v-slot:head>
      <TableHeadCell title="Fahrzeug" />
      <TableHeadCell title="Schaden" />
      <TableHeadCell title="Kunde" />
      <TableHeadCell title="Angebote (G)" />
      <TableHeadCell
        title="Eingestellt"
        :sortable="true"
        field="createdAt"
        v-model="sort"
      />
      <TableHeadCell
        title="Preis"
        :sortable="true"
        field="price"
        v-model="sort"
      />
    </template>

    <template v-slot:body>
      <Row v-for="(item, index) in items" :key="item._id" :item="item" :index="index" @delete="damageDeleteHandler(item)" />

      <el-dialog
        title="Wirklich löschen?"
        :visible.sync="deleteDamageDialogVisible"
        width="30%">
        <span>Möchtest du den Schaden wirklich löschen?</span>
        <span slot="footer" class="dialog-footer">
          <el-button :disabled="deleteDamageDialogLoading" @click="deleteDamageDialogVisible = false">Lieber doch nicht</el-button>
          <el-button :loading="deleteDamageDialogLoading" type="danger" @click="confirmDeleteDamage">Klar, Löschen</el-button>
        </span>
      </el-dialog>
    </template>

    <template v-slot:footer>
      <div
        v-if="!error && !isFetching && items.length === 0"
        class="nothing-found"
      >
        <h2>Keine Anzeigen gefunden</h2>
        <p>Versuche deine Filter anzupassen um mehr Anzeigen zu finden</p>
      </div>

      <div
        v-if="error && !isFetching"
        class="error"
      >
        <h2>Es gab ein Problem</h2>
        <p>{{ errorDescription }}</p>
      </div>
    </template>
  </Table>
</template>

<script>
import constants from "@/constants";
import { mapState } from "vuex";
import {
  FETCH_MORE_DAMAGE_LIST,
  FETCH_DAMAGE_LIST,
  APPLY_DAMAGE_SORTING,
  APPLY_DAMAGE_FILTER
} from "@/vuex/modules/damage-list/actions";
import Row from "./Row.vue";
import FilterMenu from "./FilterMenu";
import Table from "@/components/Table";
import TableHeadCell from "@/components/Table/TableHeadCell";
import apiService from '../../services/api';

export default {
  name: "DamageTable",
  props: ["view"],
  created() {
    this.$store.dispatch(FETCH_DAMAGE_LIST);
  },
  mounted() {
    this.updateScrollHandler();
  },
  data: () => ({
    sort: { field: "createdAt", order: -1 },
    deleteDamageItem: null,
    deleteDamageDialogVisible: false,
    deleteDamageDialogLoading: false
  }),
  computed: {
    ...mapState({
      env: state => state.settings.env,
      items: state => state.damageList.damages,
      isFetching: state => state.damageList.isFetching,
      error: state => state.damageList.error
    }),
    newDamagesCount() {
      return this.realtime_newDamages.length;
    },
    isUserDataMissingError() {
      return (
        this.error && this.error.message === constants.errors.userDataMissing
      );
    },
    errorDescription() {
      if (!this.error) return "";
      switch (this.error.message) {
        case constants.errors.userDataMissing:
          return "Es fehlen wichtige Informationen über ihr Profil. Bitte füllen Sie es vollständig aus.";

        default:
          return "Ein unbekannter Fehler ist aufgetrehten. Bitte versuchen Sie es später erneut.";
      }
    }
  },
  components: {
    Table,
    TableHeadCell,
    Row,
    FilterMenu
  },
  watch: {
    view() {
      this.updateScrollHandler();
    },
    sort() {
      this.applySorting(this.sort);
    },
    env() {
      this.$store.dispatch(FETCH_DAMAGE_LIST);
    },
  },
  methods: {
    damageDeleteHandler(item) {
      this.deleteDamageItem = item;
      this.deleteDamageDialogVisible = true;
    },
    async confirmDeleteDamage() {
      this.deleteDamageDialogLoading = true;
      try {
        await apiService.deleteDamage(this.deleteDamageItem._id);
        await this.$store.dispatch(FETCH_DAMAGE_LIST);
        this.deleteDamageItem = null;
        this.deleteDamageDialogVisible = false;
      } catch (error) {
        console.error(error);
        alert("Failed somehow: " + error.message);
      }
      this.deleteDamageDialogLoading = false;
    },
    updateScrollHandler() {
      const scrollElem = this.view ? this.view.parentElement : this.$el.parentElement;
      scrollElem.addEventListener("scroll", this.onScroll);
    },
    onScroll(e) {
      if (
        e.target.scrollHeight - e.target.scrollTop <
        e.target.clientHeight + 200
      ) {
        if (!this.isFetching && !this.error) {
          this.$store.dispatch(FETCH_MORE_DAMAGE_LIST);
        }
      }
    },
    applySorting(sort) {
      this.realtime_newDamages = [];
      this.$store.dispatch(APPLY_DAMAGE_SORTING, sort);
    },
    applyFilter(filter) {
      this.realtime_newDamages = [];
      this.$store.dispatch(APPLY_DAMAGE_FILTER, filter);
    }
  }
};
</script>

<style scoped lang="scss">
.filter-wrap {
  padding: 7px 0;
}

.live-damages {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .live-damages-count {
    cursor: pointer;
    background: var(--md-theme-default-primary, #448aff);
    transition: ease-out 0.4s;
    color: white;
    padding: 5px 20px;
    border-radius: 16px;

    &:hover {
      background: #6ca2ff;
    }

    .counter {
      font-weight: bold;
    }
  }
}

.nothing-found {
  margin: 50px;

  h2 {
    margin-bottom: 20px;
    color: #b3b3b3;
  }

  p {
    color: #777777;
  }
}

.error {
  margin: 50px;

  h2 {
    margin-bottom: 20px;
    color: #daa2a2;
  }

  p {
    color: #daa2a2;
  }
}
</style>
