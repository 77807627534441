<template>
  <el-popover
    trigger="click"
  >
    <el-button slot="reference">
      <fa-icon icon="filter" /> Filter
    </el-button>
    <FilterContent @submit="submit" />
  </el-popover>
</template>

<script>
import _isEqual from "lodash.isequal";
import FilterContent from "./FilterContent.vue";
import constants from "@/constants";
export default {
  name: "DamageTableFilter",
  data: () => ({
    active: false,
    default: {
      requestTypes: Object.values(constants.requestType),
      repairTypes: Object.values(constants.repairType)
    }
  }),
  created() {
    this.$emit("apply", this.default);
  },
  methods: {
    submit(filter) {
      const {
        showTurbo,
        showStandard,
        showSmartRepair,
        showProfessionalRepair,
        showLeasingRepair
      } = filter;

      const requestTypes = [
        showTurbo ? constants.requestType.turbo : null,
        showStandard ? constants.requestType.standard : null
      ].filter(x => x);

      const repairTypes = [
        showSmartRepair ? constants.repairType.smart : null,
        showProfessionalRepair ? constants.repairType.professional : null,
        showLeasingRepair ? constants.repairType.leasing : null
      ].filter(x => x);

      const newFilter = {
        requestTypes,
        repairTypes
      };

      this.$emit("apply", newFilter);

      this.active = !_isEqual(this.default, newFilter);
    }
  },
  components: {
    FilterContent
  }
};
</script>

<style scoped lang="scss">
.md-menu-content {
  max-height: 75vh;
}
.md-icon-button.active {
  i {
    color: var(--md-theme-default-primary-on-background, #448aff);
  }
}
</style>
